// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-client-js": () => import("./../../../src/pages/Client.js" /* webpackChunkName: "component---src-pages-client-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-our-service-js": () => import("./../../../src/pages/OurService.js" /* webpackChunkName: "component---src-pages-our-service-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/Portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/Process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-request-form-js": () => import("./../../../src/pages/RequestForm.js" /* webpackChunkName: "component---src-pages-request-form-js" */),
  "component---src-pages-testimoni-js": () => import("./../../../src/pages/Testimoni.js" /* webpackChunkName: "component---src-pages-testimoni-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/WhyUs.js" /* webpackChunkName: "component---src-pages-why-us-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

